import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { changeScreen } from '../../store/auth/auth-action'
import { setIsInScreen } from '../../store/following/following-action'
import { useAppDispatch, useAppSelector } from '../../store/hooks'
import { setActiveCorporateId } from '../../store/impact/impact-action'
import { BaseBrand, LoggedInBrand } from '../../types/api'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'

import './style.scss'

interface Props {
    data: BaseBrand | LoggedInBrand
    text: string
    isFiltersShow: boolean
}
const Photos: React.FC<Props> = ({ data }) => {
    const { user } = useAppSelector((state) => state.auth)
    const dispatch = useAppDispatch()
    const navigate = useNavigate()
    const pathLang = getCurrentLanguagePath()
    const openDetails = () => {
        const countryValue: string = data.country?.name
            ? `__${data.country.alpha3}`
            : ``

        if (user) {
            dispatch(setIsInScreen(false))
            dispatch(setActiveCorporateId(data.id))
            dispatch(changeScreen('Corporate Details'))
            navigate(
                `${pathLang}/brand/` +
                    data.name.replaceAll(' ', '_') +
                    countryValue
            )
        } else {
            dispatch(changeScreen('Start Screen'))
            navigate(
                `${pathLang}/brand/` +
                    data.name.replaceAll(' ', '_') +
                    countryValue
            )
        }
    }

    const handleSiteClick = () => {
        const redirectUrl = data.affiliate_link
            ? data.affiliate_link
            : 'https://' + data?.site
        if (user) {
            window.open(redirectUrl, '_blank', 'noopener,noreferrer')
        } else {
            navigate(`${pathLang}/menu`)
        }
    }

    return (
        <div className="photo">
            <div
                className="photo-container"
                style={{
                    backgroundImage:
                        data.photo?.url && !data.hide_background_image
                            ? `url(${data.photo.url})`
                            : 'none',
                }}
                onClick={openDetails}
            >
                {(data.hide_background_image || !data.photo) &&
                    data.logo &&
                    data.logo.url &&
                    !data.hide_logo && (
                        <div className="photo-middle-section">
                            <img
                                className="photo-logo"
                                src={data.logo.url}
                                alt={data.name}
                            />
                        </div>
                    )}

                {!data.hide_name &&
                    ((data.photo?.url && !data.hide_background_image) ||
                        !data.logo ||
                        data.hide_logo) && (
                        <div className="photo-middle-section">
                            <h5
                                className="photo-name"
                                style={{
                                    color:
                                        data.photo?.url &&
                                        !data.hide_background_image
                                            ? '#fff'
                                            : '#000',
                                }}
                            >
                                {data.name}
                            </h5>
                        </div>
                    )}
            </div>
            {data.commission_type === 'percentage' && (
                <div className="photo-percentage">
                    {data.affiliate_percent
                        ? data.affiliate_percent + '%'
                        : '0'}
                </div>
            )}

            {data.commission_type === 'fixed' && (
                <div className="photo-percentage percentage-fixed">
                    <span>
                        {data.affiliate_percent ? data.affiliate_percent : '0'}
                    </span>
                    <span>{data.commission_currency}</span>
                </div>
            )}

            <div className="photo-site">
                <button
                    onClick={handleSiteClick}
                    className={`photo-site ${
                        data?.site && data?.site?.length > 18
                            ? 'photo-site-sm'
                            : 'photo-site-bg'
                    }`}
                >
                    {data?.site}
                </button>
            </div>
        </div>
    )
}

export default Photos
