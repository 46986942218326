interface SetCountryIdAction {
    type: 'SET_COUNTRY_ID';
    payload: string;
  }
  
  type CountryAction = SetCountryIdAction;
  

  const initialState = {
    country_id: '',
  };
  
  export const countryReducer = (
    state = initialState,
    action: CountryAction
  ): typeof initialState => {
    switch (action.type) {
      case 'SET_COUNTRY_ID':
        return {
          ...state,
          country_id: action.payload,
        };
      default:
        return state;
    }
  };
  