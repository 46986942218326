import { useNavigate } from 'react-router-dom'
import logo_black from '../../assets/images/LOGO_BLACK.png'
import close from '../../assets/images/svgs/close.svg'
import { Button } from '../../components/button'
import { changeScreen } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { useTranslation } from 'react-i18next'
import { getCurrentLanguagePath } from '../../translation/getCurrentLanguagePath'
import './styles.scss'
import CountryDropdown from '../../components/country-dropdown'
import { RootState } from '../../store'
import { setCountryId } from '../../store/country/country-action';
import { useSelector } from 'react-redux'

const StartScreen = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const pathLang = getCurrentLanguagePath();

    const country_id = useSelector((state: RootState) => state.country.country_id);

    const _setvalue = (setter: (e: string) => void, e: string) => {
        dispatch(setCountryId(e));
    };

    const _closeModal = () => {
        navigate("/" + pathLang);
    };

    const _signUp = () => {
        dispatch(changeScreen('Sign Up'));
    };

    const _signIn = () => {
        dispatch(changeScreen('Sign In'));
    };

    return (
        <div className="container">
            
            <div className="container__close-wrapper">
                
                <img
                    className="container-close"
                    onClick={_closeModal}
                    src={close}
                />
            </div>
            <div className="container__body">
                <div className="container__logo-container">
                    <img src={logo_black} className="container_logo" />
                </div>
                <CountryDropdown
                value={country_id}
                setValue={(e: string) => _setvalue(setCountryId, e)}
                placeholderValue='Select Country for the correct signup process'
            />
                <div className="container__sign-up-wrapper">
                    <Button
                        variant="white-outlined"
                        text={t("sign_up")}
                        onClick={_signUp}
                    />
                </div>

                <p className="container_already-text">
                    {t("already_have_an_account")}{' '}
                    <span onClick={_signIn} className="container_sign-in">
                        {t("log_in")}
                    </span>
                </p>
            </div>
        </div>
    );
};

export default StartScreen;