import { useState } from 'react'
import arrowNext_Icon from '../../assets/images/svgs/arrow-next.svg'
import Delete_Icon from '../../assets/images/svgs/delete-account.svg'
import EditProfile_Icon from '../../assets/images/svgs/edit-profile.svg'
import Logout_Icon from '../../assets/images/svgs/logout.svg'
import TermsOfUse_Icon from '../../assets/images/svgs/terms-of-use.svg'
import { SettingsButton } from '../../components/settings-button'
import SettingsHeader from '../../components/settings-header'
import { changeScreen, logOut } from '../../store/auth/auth-action'
import { useAppDispatch } from '../../store/hooks'
import { DeleteAccount } from '../delete-account'
import { useTranslation } from 'react-i18next'
import './styles.scss'
import { Menu_buttons } from '../../components/menu_button/menu_button'

const Settings = () => {
    const [deleteAccount, setDeleteAccount] = useState(false)
    const dispatch = useAppDispatch()
    const { t } = useTranslation()

    const _navigationHandler = (screen: string) =>
        dispatch(changeScreen(screen))

    const _logout = () => {
        dispatch(logOut())
        _navigationHandler('Start screen')
    }
    const _delete_account = () => {
        setDeleteAccount(true)
    }
    return (
        <div className="settings-container">
            <SettingsHeader
                screen="Menu Screen"
                text={t('settings').toUpperCase()}
            />
            <div className="settings-container__buttons">
                <SettingsButton
                    text={t('edit_profile')}
                    arrow={arrowNext_Icon}
                    onClick={() => _navigationHandler('Edit Profile')}
                />
                <SettingsButton
                    text={t('preferences')}
                    onClick={() =>  _navigationHandler('Select Interest')}
                />
                <SettingsButton
                    text={t('terms_of_use')}
                    arrow={arrowNext_Icon}
                    onClick={() => _navigationHandler('Terms Of Use')}
                />
                <SettingsButton text={t('logout')} onClick={_logout} />
                <SettingsButton
                    text={t('delete_account')}
                    onClick={_delete_account}
                />
            </div>
            {deleteAccount && (
                <DeleteAccount setDeleteAccount={setDeleteAccount} />
            )}
        </div>
    )
}

export default Settings
